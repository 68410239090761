@use 'mixins';
@use 'sass:math';

.NewsItem {
    display: flex;
    align-items: center;
    flex-direction: row;
    padding: var(--standardPadding);
    line-height: 1.25em;
    border-bottom: 1px solid rgb(var(--sectionBorder));

    &:global(.transferrumors-article) {
        padding: var(--largePadding) var(--standardPadding) 0 var(--standardPadding);
        border-bottom: 0;
    }

    h4.extra-bold {
        font-weight: var(--font-weight-extra-bold);
    }

    &.dossier {
        padding-left: 0;
        border-bottom: none;
    }

    :global(.Icon.comment-icon) {
        width: 1em;
        font-size: 1em;
    }

    .comment-text {
        display: none;
    }

    :global(.tag) {
        text-transform: uppercase;

        :global(.theme-br) & {
            font-weight: var(--font-weight-bold);
        }
    }

    /* MAIN COMMENT STYLES - REMOVE THESE COMMENTS WHEN CODE REVIEWING */
    &.compact,
    &.block-type,
    &.list {
        .comments {
            .comment-count {
                margin-right: auto;
                margin-left: initial;
                vertical-align: bottom;
            }

            :global(.Icon.comment-icon) {
                width: 1em;
                height: 1rem;
                margin-right: auto;
                margin-left: initial;
                vertical-align: baseline;
            }
        }
    }

    &.list,
    &.block-type {
        .comments {
            .comment-count {
                margin-left: var(--tinyPadding);
            }

            :global(.Icon.comment-icon) {
                vertical-align: text-top;
            }
        }
    }

    /* MAIN CONTENT */
    .content {
        a {
            color: rgb(var(--textColor));

            &:visited {
                color: rgb(var(--lighterTextColor));
            }
        }

        .title {
            :global(.theme-gp) & {
                line-height: 1.25em;
            }
        }
    }

    /* MAIN META */
    .meta {
        @extend %caption-small;

        line-height: 1.25em;

        .comments,
        .long-date {
            color: rgb(var(--lighterTextColor));
        }
    }

    .time {
        display: none;

        :global(.Icon.play-icon) {
            display: none;
        }
    }

    &:global(.betting-article) {
        :global(.betting-disabled) & {
            display: none;
        }
    }

    .pro-tag {
        font-size: 1em;
        display: flex;
        align-items: center;
        height: 2em;
        overflow: hidden;
        margin-top: -2em;
        position: relative;

        :global(.Icon) {
            width: 2.6em;
            height: 2.6em;
        }

        span {
            &:nth-child(2) {
                @extend %caption-small;

                background: rgb(var(--background));
                color: rgb(var(--textColor));
                line-height: 1em;
                display: block;
                padding: 0.35em 0.5em;
                border-radius: 0;
            }

            &:last-of-type {
                margin-right: var(--smallPadding);
            }
        }
    }

    &.compact {
        .time {
            @extend %caption-small;

            display: block;
            margin-top: 0;
            margin-right: var(--smallPadding);
            margin-bottom: auto;
            color: rgb(var(--lighterTextColor));
        }

        .radio {
            position: absolute;
            display: inline-block;
            width: 1.25em;
            height: 1.25em;
            background-color: rgb(var(--invertedBackground));
            border: var(--standardRadius) solid rgb(var(--background));
            border-radius: 50%;

            &::before {
                content: '';
                position: absolute;
                min-height: 3em;
                padding-top: 0;
                margin-left: var(--smallPadding);
                margin-top: var(--largePadding);
                border-left: var(--tinyPadding) dotted rgb(var(--background));
                opacity: 0.2;
            }

            &::after {
                content: '';
                display: flex;
                position: relative;
                width: 0.625em;
                height: 0.625em;
                margin: auto;
                transform: translateY(-50%);
                top: 50%;
                background-color: rgb(var(--background));
                border-radius: 50%;
            }
        }

        .image {
            display: none;
        }

        h4 {
            margin-left: calc(var(--tinyPadding) / 2);

            > span {
                margin-right: var(--tinyPadding);
            }
        }

        :global(.tag) {
            margin-right: var(--tinyPadding);
        }

        .content {
            display: flex;
            flex-direction: row;
            width: 100%;

            a {
                text-decoration: none;
            }
        }

        .meta {
            margin-left: auto;
        }

        .long-date {
            display: none;
        }

        .comments {
            display: flex;
            align-items: center;
            margin-left: auto;

            :global(.Icon.comment-icon) {
                margin-right: var(--tinyPadding);
            }
        }

        .title {
            display: inline;
        }
    }

    &.list {
        .content {
            flex-direction: column;
            padding-left: var(--standardPadding);
            font-size: 1rem;

            :global(.theme-vi) & {
                padding: 0;
            }

            h4 {
                display: inline;
                text-decoration-line: none;
            }

            a:hover p {
                text-decoration: underline;
            }
        }

        a {
            :global(.theme-br) & {
                max-height: 3.75em;
            }
        }

        .comments {
            display: flex;
            margin-left: var(--tinyPadding);
            line-height: 1.25em;
            align-items: center;
        }

        .time {
            display: none;
        }

        .image {
            display: block;
            height: auto;
            width: 6.875em;
        }

        h4 {
            width: fit-content;
            margin: 0 0 var(--tinyPadding) 0;
        }

        .meta {
            display: flex;
            line-height: 1.5em;

            :global(.theme-vp) & {
                text-decoration: none;
            }
        }

        .long-date {
            display: unset;
        }

        .pro-tag {
            display: inline-flex;
            align-items: center;
            margin: 0;
            height: 1em;

            :global(.Icon) {
                width: 2.2em;
                height: 2.2em;
            }

            span:nth-child(2) {
                padding: 0.15em 0.5em;
            }
        }

        :global(.tag) {
            margin-right: var(--tinyPadding);
        }

        .title-wrapper {
            display: inline;

            :global(.theme-br) & {
                display: block;
            }

            :global(.theme-vi) & {
                display: inline-flex;
                align-items: center;
            }
        }

        .title {
            display: inline;

            :global(.theme-br) & {
                display: block;
                margin-top: var(--tinyPadding);

                &:only-child {
                    margin-top: 0;
                }
            }
        }
    }

    /* block-type is DisplayType blocks or carrousel. */
    &.block-type {
        align-items: flex-start;
        flex-direction: column;
        max-width: calc(33.3% - 0.75em);
        padding: var(--standardPadding) calc(var(--standardPadding) * 0.5);
        border-bottom: none;
        border-radius: var(--standardRadius);

        :global(.theme-vi) & {
            flex: 0 1 math.div(100, 3) + '%';
            padding: 0;
            max-width: initial;
        }

        .comments {
            display: flex;
            width: min-content;
            align-items: center;
            margin-left: initial;
        }

        .content {
            padding: 0;
        }

        a {
            width: 100%;
            position: relative;
        }

        .play-video {
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
        }

        h4 {
            margin-top: var(--standardPadding);
            margin-bottom: var(--tinyPadding);

            /* To show ellipsis on the third line of the title. */
            display: -webkit-box;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;

            :global(.theme-vi) & {
                color: rgb(var(--textColor));

                &:hover,
                &:focus {
                    color: rgb(var(--mainColorLighter));
                }
            }
        }

        .meta {
            :global(.theme-vn) & {
                display: none;
            }
        }

        .time {
            :global(.theme-vi) & {
                display: block;
                background: rgb(var(--mainColorLighter));
                color: rgb(var(--background));
                border-radius: 0.75em;
                padding: 0.25em 0.75em 0.25em 1.6em;
                position: absolute;
                left: 0.75em;
                bottom: 0.75em;
                font-weight: 600;
                letter-spacing: 0.2px;
                box-shadow: 1px 1px 4px rgba(var(--invertedBackground), 0.5);
                z-index: 2;

                &::before {
                    @include mixins.arrow(rgb(var(--background)), rgb(var(--background)), 0.8em, 'right');

                    content: '';
                    position: absolute;
                    top: calc(50% - 0.35em);
                    left: 0.75em;
                }
            }
        }

        .long-date {
            :global(.theme-vp) & {
                display: none;
            }
        }

        .image {
            display: block;
            object-fit: cover;
            width: 100%;
            height: auto;

            :global(.theme-gp) & {
                border-top: 3px solid rgb(var(--thirdColor));
                border-radius: var(--standardRadius);
                border-color: rgb(var(--fourthColor));
            }

            :global(.theme-vi) & {
                height: auto;
            }

            :global(.theme-br) & {
                height: 7.0625em;
            }
        }

        .tag-container {
            display: none;

            :global(.theme-gp) & {
                position: absolute;
                display: block;
                width: fit-content;
                margin-left: var(--smallPadding);
                background-color: rgb(var(--fourthColor));
                border-radius: var(--standardRadius);

                p {
                    margin: 1px var(--smallPadding);
                    font-size: 0.75em;
                    line-height: 1.5em;
                    text-transform: uppercase;
                    white-space: nowrap;
                    font-weight: 700;
                    letter-spacing: 0.4px;
                    color: rgb(var(--invertedTextColor));
                }
            }
        }

        &:hover h4 {
            text-decoration: underline;
        }

        :global(.tag) {
            display: none;
        }

        :global(.is-highlighted) & {
            .time {
                :global(.theme-br) & {
                    position: absolute;
                    display: block;
                    padding: 0 var(--smallPadding);
                    color: rgb(var(--invertedTextColor));
                    background: rgb(var(--invertedBackground));
                    font-family: var(--textFont);
                    font-size: 0.75em;
                    font-weight: 600;
                    line-height: 1.5em;
                    letter-spacing: 0.2px;
                    left: 1em;
                    bottom: 0.85em;
                    z-index: 1;

                    :global(.Icon.play-icon) {
                        position: absolute;
                        display: block;
                        width: 1.5em;
                        height: 1.5em;
                        padding-left: 0.2em;
                        color: rgb(var(--invertedTextColor));
                        background: rgb(var(--mainColor));
                        box-sizing: border-box;
                        left: -1.5em;
                        top: 0;
                    }
                }
            }

            .image {
                overflow: hidden;
                border: 1px solid rgba(var(--invertedTextColor), 0.2);
                border-radius: var(--standardRadius);
            }

            .play-button {
                display: none;
            }

            h4 {
                :global(.theme-br) & {
                    .title {
                        color: rgb(var(--invertedTextColor));
                        text-decoration: none;
                        margin-top: 0;
                        font-size: 1em;
                        line-height: 1.125em;
                    }
                }
            }

            .meta {
                :global(.theme-br) & {
                    display: none;
                }
            }
        }
    }

    &.blocks {
        /* 0.75em is the sum of the paddings of the NewsListItems. */
        flex: 0 1 calc(33.3% - 0.75em);

        :global(.theme-vi) & {
            flex: inherit;
        }
    }

    &.carrousel {
        scroll-snap-align: start;
        flex: 1 0 calc(33.3% - 0.75em);

        :global(.theme-br) & {
            flex: 1 0 calc(29% - 0.75em);
            padding-top: 0;
            padding-bottom: 0;
        }

        .content {
            z-index: 1;
        }
    }

    &.pro {
        position: relative;

        &.list {
            background: rgb(var(--invertedBackground));
        }

        .play-video {
            position: relative;
            overflow: hidden;

            &::after {
                background: linear-gradient(180deg, transparent, #000 85%);
                bottom: -25px;
                content: '';
                display: block;
                height: 50%;
                position: absolute;
                width: 100%;
            }

            .time {
                bottom: 1.5em;
            }
        }

        .content {
            padding: 0.8em;
        }

        .meta {
            display: none;
        }

        :global(.theme-vi) & {
            &.list {
                background: rgb(var(--invertedBackground));
            }

            h4 {
                color: rgb(var(--invertedTextColor));
                font-family: var(--font-family-yrsa);
                font-size: 1.3em;

                &:hover,
                &:focus {
                    color: rgb(var(--invertedTextColor));
                }
            }
        }

        &.block-type {
            .content {
                padding: 0;
            }
        }
    }

    /* stylelint-disable-next-line selector-class-pattern */
    :global(.Icon.iconVideo-icon) {
        display: initial;
        color: rgb(var(--background));
    }
}

@include mixins.responsive(m, below) {
    .NewsItem {
        align-items: start;

        .time {
            align-self: center;

            /* default platforms have flag and time */
            grid-row: 2 / span 1;
            grid-column: 1 / span 1;

            :global(.theme-br) &,
            :global(.theme-gp) & {
                margin-top: 0.35em;

                /* br and gp platforms only have time */
                grid-row: 1 / span 1;
            }
        }

        .content {
            grid-row: 1 / stretch;
            grid-column: 2 / span 1;
            align-self: center;
        }

        .meta {
            :global(.theme-br) & {
                display: none;
            }
        }

        &.compact {
            display: grid;
            grid-template-columns: minmax(0, auto) minmax(0, 1fr);

            /* 2 rows because default has flag and time */
            grid-template-rows: minmax(1.25em, auto) minmax(auto, 1.25em);

            :global(.theme-br) &,
            :global(.theme-gp) & {
                /* 1 row because br and gp have no flag */
                grid-template-rows: minmax(1.25em, auto);
            }

            .comments {
                display: grid;
                flex-direction: column;
                width: 2em;
                line-height: 1.25em;
                grid-template-rows: 1fr minmax(0, 1fr);

                .comment-count {
                    /* MOBILE VIEW COMMENTS HORIZONTAL ALIGNMENT
                     COUNT AND ICON ARE STACKED ON TOP OF EACH OTHER */
                    margin-right: auto;
                    margin-left: auto;
                }

                :global(.Icon.comment-icon) {
                    display: inline-flex;
                    margin-right: auto;
                    margin-left: auto;
                }
            }
        }

        &.block-type {
            max-width: calc(50% - 0.75em);

            .image {
                height: 30vw;

                :global(.theme-br) & {
                    height: 27vw;
                }
            }

            :global(.is-highlighted) & {
                :global(.theme-br) & {
                    max-width: 60%;
                }

                .time {
                    :global(.theme-br) & {
                        top: 25.5vw;
                    }
                }
            }
        }

        &.blocks {
            flex: 0 1 calc(50% - 0.75em);
        }

        &.carrousel {
            flex: 1 0 calc(50% - 0.75em);

            :global(.theme-br) & {
                flex: 1 0 60%;
            }
        }

        &.list {
            .content {
                :global(.theme-br) & {
                    max-height: fit-content;
                }

                .title-wrapper {
                    display: inline-block;
                }

                .title {
                    display: inline;
                }
            }
        }
    }
}

@include mixins.responsive(m, above) {
    .NewsItem {
        &.compact,
        .comments {
            padding-left: var(--smallPadding);
            flex-direction: row;
        }

        &.block-type {
            .comments {
                padding-left: 0;
            }
        }
    }
}

@include mixins.responsive(l, below) {
    .NewsItem {
        &.block-type {
            img {
                height: 13vw;

                :global(.theme-vi) & {
                    height: auto;
                }
            }
        }

        &.compact {
            :global(.theme-br) &,
            :global(.theme-gp) & {
                .content {
                    align-self: initial;
                }

                .time {
                    margin-top: 0;
                }
            }
        }
    }
}

@include mixins.responsive(xxxl, above) {
    .NewsItem.block-type {
        .Image {
            max-height: 160px;
        }
    }
}
